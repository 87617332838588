import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcess = () => {
  return (
    <>
      <section className='work-process ptb-60'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle='Our Approach'
                title='Our Approach to AI Consulting'
                description='From initial assessment to the continuous improvement of AI-driven systems, we ensure a strategic, tailored approach for seamless technological integration and sustained success.'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                <img
                  src='assets/img/office-img-1.jpg'
                  alt='AI consulting process'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-folder-tree fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Discovery & Strategy</span>
                    <h3 className='h5 mb-2'>Discovery Phase & Strategic Planning</h3>
                    <p>
                      Initiating with a comprehensive understanding of your organization’s current state and needs, we develop a strategic, customized AI roadmap.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-bezier-curve fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Model Development & Integration</span>
                    <h3 className='h5 mb-2'>AI Model Development & Integration</h3>
                    <p>
                      Crafting custom AI models from quality data and seamlessly integrating these solutions into your business operations for enhanced efficiency.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start mb-4'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-sync-alt fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Adoption & Optimization</span>
                    <h3 className='h5 mb-2'>Seamless Adoption & Continuous Optimization</h3>
                    <p>
                      Ensuring smooth adoption of AI technologies within your workflows and continuously refining these systems for optimal performance.
                    </p>
                  </div>
                </li>
                <li className='d-flex align-items-start'>
                  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                    <i className='far fa-tools fa-2x'></i>
                  </div>
                  <div className='icon-content'>
                    <span className='text-primary h6'>Maintenance & Growth</span>
                    <h3 className='h5 mb-2'>Ongoing Maintenance & Strategic Growth</h3>
                    <p>
                      Providing continuous oversight and maintenance to adapt to new challenges and opportunities, ensuring long-term AI success and scalability.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
