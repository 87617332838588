import React, { useState } from 'react';

const ContactsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataObj = { name, email, phone_number: phoneNumber, message };

    try {
      const response = await fetch("https://airtable-form-handler.allodatpvtltd.workers.dev/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formDataObj).toString(),
      });

      if (response.ok) {
        setSubmissionMessage("Success! We will contact you soon.");
        setIsError(false);
        // Reset form fields
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
      } else {
        setSubmissionMessage("Unable to send response. Please try again.");
        setIsError(true);
      }
    } catch (error) {
      setSubmissionMessage("Network error. Please check your connection.");
      setIsError(true);
    }
  };

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Fill out the form and we'll be in touch as soon as possible.
          </h3>
          <form onSubmit={handleSubmit} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='Name *'
                    aria-label='name'
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    name='email'
                    className='form-control'
                    placeholder='Email *'
                    aria-label='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='tel'
                    name='phone_number'
                    className='form-control'
                    placeholder='Phone Number'
                    aria-label='Phone Number'
                    pattern="\d{10,14}"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    name='message'
                    className='form-control'
                    placeholder='Tell us more about your project, needs and budget.*'
                    style={{ height: '120px' }}
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexCheckDefault'
                    required
                  />
                  <label className='form-check-label small' htmlFor='flexCheckDefault'>
                    Agree to the terms and privacy policy *
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <button type='submit' className='btn btn-primary mt-4 d-block w-100'>
                  Get Started
                </button>
              </div>
            </div>
            {submissionMessage && (
              <p style={{ color: isError ? "red" : "green", marginTop: "20px" }}>
                {submissionMessage}
              </p>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
