import React from 'react';
import BlogDetails2 from '../../components/blog/BlogDetails2';
import PageMeta from '../../components/common/PageMeta';
import NewsLetter from '../../components/cta/NewsLetter';
import PageHeader from '../../components/common/PageHeader';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import LatestBlog from '../../components/blog/LatestBlog';
import CtaTwo from '../../components/cta/CtaTwo';

const SingleBlog1 = () => {
  return (
    <Layout>
      <PageMeta title="Allodat- AI Consulting" />
      <Navbar navDark />
      <PageHeader title="ComplyNet: Trailblazer in compliance and security automation" />
      <BlogDetails2 />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default SingleBlog1;
