const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How does back pricing work?',
    faqDesc:
      'Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.',
  },
  {
    id: 2,
    faqTitle: 'How do I calculate how much price?',
    faqDesc:
      'Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.',
  },
  {
    id: 3,
    faqTitle: 'Can you show me an example?',
    faqDesc:
      'Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.',
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Ava Johnson',
    authorTitle: 'CTO at TechInnovate Solutions',
    quoteTitle: 'Transformative AI Solutions',
    authorQuote: "Allodat's AI expertise has revolutionized our product development, exceeding our expectations."
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Al Fayed',
    authorTitle: 'Data Scientist at NextGen Analytics',
    quoteTitle: 'Cutting-Edge AI Implementation',
    authorQuote: "Allodat's team turned our data challenges into opportunities with their innovative AI strategies."
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'Emily Carter',
    authorTitle: 'CEO at GreenTech Innovations',
    quoteTitle: 'AI-Driven Growth',
    authorQuote: "Thanks to Allodat, our operational efficiency has improved dramatically with AI integration."
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Rajesh Singh',
    authorTitle: 'Director of IT Services at Global Solutions Inc.',
    quoteTitle: 'Exceptional AI Consultancy',
    authorQuote: "Allodat's AI consulting has been pivotal in our journey towards digital transformation."
  }
];

const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-1.jpg',
    authorAvatar: 'assets/img/testimonial/1.jpg',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: 'assets/img/testimonial/t-2.jpg',
    authorAvatar: 'assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-3.jpg',
    authorAvatar: 'assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-4.jpg',
    authorAvatar: 'assets/img/testimonial/4.jpg',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-5.jpg',
    authorAvatar: 'assets/img/testimonial/5.jpg',
  },
];

export { IconBoxData, FaqOneData, TestimonialData, TestimonialOneData };
