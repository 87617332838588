import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import CtaTwo from '../../components/cta/CtaTwo';
import OpenJobs from '../../components/career/OpenJobs';
import CareerPromo from '../../components/career/CareerPromo';
import PageHeader from '../../components/common/PageHeader';

const Career = () => {
  return (
    <Layout>
      <PageMeta title="Allodat: AI Consulting" />
      <Navbar navDark />
      <PageHeader
        title="Careers at Allodat"
        desc="Embark on a journey where your passion for AI innovation meets opportunity. Join us in shaping a smarter future, today."
      />
      <OpenJobs />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Career;
