import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgEight2 = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-60'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title='Custom Software Dev'
                  description="We are an AI-focused software development company catering to a broad clientele across diverse industries. Our expertise extends beyond AI, encompassing Web3, IoT, and more. Transform your ideas into reality with our custom software development services, ranging from DeFi apps and LLM-based chatbots to IoT-powered tools."
                />
              </div>
              <ul
                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-network-wired fa-2x text-primary'></i>
                  </span>
                  <h3 className='h5'>Broad Industry Expertise</h3>
                  <p>
                    Serving industries like retail, finance, education, and more with tailored AI solutions.
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-cogs fa-2x text-primary'></i>
                  </span>
                  <h3 className='h5'>Advanced Technologies</h3>
                  <p>
                    Leveraging AI, Web3, and IoT to build innovative and efficient solutions.
                  </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/services_gen_ai/3.svg'
                    alt='Custom Software Development'
                    className='img-fluid'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgEight2;
