import React from 'react';
import { Link } from 'react-router-dom';
import BlogPagination from './BlogPagination';

const BlogGrid = () => {
  return (
    <>
      <section className='masonary-blog-section ptb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/wine-wizard' className='article-img'>
                  <img
                    src='assets/img/wine/3.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-warning-soft'
                    >
                      AI App
                    </Link>
                  </div>
                  <Link to='/wine-wizard'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Unveiling WineWizard: Revolutionizing the Wine Shopping Experience with AI
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Dive into how WineWizard, our innovative AI application, transforms the wine e-commerce landscape by offering personalized recommendations, simplifying the search for the perfect wine with detailed insights and efficient query handling.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/team/team-2.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Shreya Misra</h6>
                        <span className='small fw-medium text-muted'>
                          January 24, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/comply-net' className='article-img'>
                  <img
                    src='assets/img/comply-net/1.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      AI Chatbot
                    </Link>
                  </div>
                  <Link to='/comply-net'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      ComplyNet Unveiled: Transforming Compliance Navigation with AI
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Explore how ComplyNet, our AI-driven solution, streamlines the complex landscape of compliance and security, offering instant access to benchmarks, frameworks, and personalized guidance to revolutionize client engagement and knowledge.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/team/team-2.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Shreya Misra</h6>
                        <span className='small fw-medium text-muted'>
                          January 24, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <BlogPagination />
        </div>
      </section>
    </>
  );
};

export default BlogGrid;
