import React from 'react';
import ProfileCard from './ProfileCard';

const BlogDetails2 = () => {
  return (
    <>
      <section className='blog-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>
                <p>
                  ComplyNet, a trailblazer in compliance and security automation, faced a formidable challenge: enabling clients to navigate a vast sea of compliance benchmarks, frameworks, and audit-related data efficiently. This complexity often overwhelmed clients, hindering their ability to access tailored information swiftly.
                </p>
                <p>
                  In response, ComplyNet collaborated with LeewayHertz to conceive an innovative solution—ComplyNet's Innovative AI Application, powered by LLM and developed using ZBrain. This application was designed to revolutionize how clients interact with compliance data, providing a seamless, intuitive interface for accessing personalized compliance and security audit information.
                </p>
                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>Innovating Compliance Navigation with ComplyNet's AI Application</h3>
                  <ul className='content-list list-unstyled'>
                    <li>
                      Instant access to an extensive array of compliance benchmarks and frameworks, including SOC 2, ISO 27001, and GDPR, among others.
                    </li>
                    <li>
                      Efficient query responses, allowing users to receive customized answers and insights quickly.
                    </li>
                    <li>
                      An easy-to-use interface, crafted for effective query handling and straightforward navigation.
                    </li>
                    <li>
                      Enhanced decision-making capabilities, fueled by fast, accurate compliance information.
                    </li>
                  </ul>
                  <blockquote className='bg-white custom-shadow p-5 mt-5 rounded-custom border-4 border-primary border-top'>
                    <p className='text-muted'>
                      <i className='fas fa-quote-left me-2 text-primary'></i>
                      By streamlining the complexities of security compliance into an accessible, user-friendly platform, ComplyNet's AI Application not only elevates the client experience but also empowers informed decision-making regarding security compliance strategies.
                      <i className='fas fa-quote-right ms-2 text-primary'></i>
                    </p>
                  </blockquote>
                </div>
                <div className='job-details-info mt-5'>
                  <h3 className='h5'>
                    Empowering Clients with Instant Compliance Insights
                  </h3>
                  <p>
                    The application's cutting-edge features and user-centric design underscore ComplyNet's commitment to enhancing client relations and streamlining the information retrieval process, thereby setting a new standard in the compliance and security industry.
                  </p>
                  <ul className='content-list list-unstyled'>
                    <li>
                      Users can effortlessly explore and comprehend complex compliance frameworks with just a few clicks.
                    </li>
                    <li>
                      The app's multilingual support ensures broader accessibility and user satisfaction.
                    </li>
                    <li>
                      Real-time data access transforms the way clients plan and execute their security compliance strategies.
                    </li>
                  </ul>
                </div>

                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>Technological Backbone of ComplyNet's AI Application</h3>
                  <ul className='content-list list-unstyled'>
                    <li>Employing ReactJS and NextJS for a responsive, modern user interface.</li>
                    <li>Leveraging Python and NodeJS for backend processing and data handling.</li>
                    <li>Utilizing Azure for cloud-based scalability and security.</li>
                    <li>Integrating GPT-4 for advanced natural language understanding and responses.</li>
                  </ul>
                </div>

                <img
                  src='assets/img/comply-net/1.png'
                  className='img-fluid mt-5 rounded-custom'
                  alt='Technology stack illustration'
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <ProfileCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails2;
