import React, { useState } from "react";
import { Link } from "react-router-dom";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  const [email, setEmail] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataObj = { email };

    try {
      const response = await fetch(
        "https://airtable-form-handler.allodatpvtltd.workers.dev/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formDataObj).toString(),
        }
      );

      if (response.ok) {
        setSubmissionMessage("Thank you! We Will Reach you soon.");
        setIsError(false);
        setEmail(""); // Reset email field after successful submission
      } else {
        setSubmissionMessage("Error! Please try again.");
        setIsError(true);
      }
    } catch (error) {
      setSubmissionMessage("Network error. Please check your connection.");
      setIsError(true);
    }
  };

  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-120`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-6 mb-md-4 mb-lg-0">
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4">
                    <img
                      src="assets/img/allodat.png"
                      alt="logo"
                      className="img-fluid logo-white"
                    />
                    <img
                      src="assets/img/allodat.png"
                      alt="logo"
                      className="img-fluid logo-color"
                    />
                  </div>
                  <p>
                    At Allodat, we blend innovation with expertise to deliver AI
                    and LLM consulting services that redefine industries.
                    Discover how our custom AI solutions can transform your
                    business landscape.
                  </p>
                  <form
                    onSubmit={handleSubmit}
                    className="newsletter-form position-relative d-block d-lg-flex d-md-flex"
                  >
                    <input
                      type="text"
                      className="input-newsletter form-control me-2"
                      placeholder="Enter your email"
                      name="email"
                      required=""
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      className="btn btn-primary mt-3 mt-lg-0 mt-md-0"
                    />
                  </form>
                  {submissionMessage && (
                    <p
                      style={{
                        color: isError ? "red" : "green",
                        marginTop: "20px",
                      }}
                    >
                      {submissionMessage}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Links</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us" className="text-decoration-none">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/services" className="text-decoration-none">
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/career" className="text-decoration-none">
                            Career
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact-us"
                            className="text-decoration-none"
                          >
                            Contact-Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-7 col-lg-7">
                <div className="copyright-text">
                  <p className="mb-lg-0 mb-md-0">
                    &copy; 2020 Allodat Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="footer-single-col text-start text-lg-end text-md-end">
                  <ul className="list-unstyled list-inline footer-social-list mb-0"></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
