import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';

const HeroSix = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                data-aos='fade-right'
              >
                <HeroTitle
                  title=' Gen AI Consulting'
                  desc='       We offer comprehensive generative AI consulting services enabling you to harness the potential of generative AI for improved operations and enhanced product offerings. We combine our deep AI expertise in technologies like GANs, VAEs, and autoencoders with a strong ethical foundation to ensure that the AI solutions we deliver are not only technologically advanced but also aligned with ethical and responsible AI practices.'
                />
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 mt-5'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-screen-wrap'>
                  <div className='mac-screen'>
                    <img
                      src='assets/img/gen_ai/gen_ai_consulting_company.png'
                      alt='hero '
                      className='position-relative img-fluid rounded-custom'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSix;
