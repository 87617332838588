import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgEight2 from '../../components/features/FeatureImgEight2';
import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import LatestBlog from '../../components/blog/LatestBlog';

const Services = () => {
  return (
    <Layout>
      <PageMeta title="Allodat: AI Consulting" />
      <Navbar navDark />
      <PageHeader
        title="Allodat Software Services"
        desc="Within Allodat, we're dedicated to transforming visionary concepts into real-world applications, offering unparalleled software services that stand out for their quality and innovation. Leveraging our global expertise, we harness strategic data insights and seamless execution to drive your business forward in the ever-evolving digital landscape."
      />
      <FeatureImgEight paddingTop />
      <FeatureImgContentThree />
      <FeatureImgEight2 paddingTop />
      <RequestForDemo />
      <LatestBlog/>
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Services;
