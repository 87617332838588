import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import HeroMain from './HeroMain';
import CtaOne from '../../components/cta/CtaOne';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import IntegrationTwo from '../../components/integration/IntegrationTwo';
import Customer from '../../components/customer/Customer';

import WorkProcess from '../../components/work-process/WorkProcess';
import FeatureFour from '../../components/features/FeatureFour';
import LatestBlog from "../../components/blog/LatestBlog";

import FeatureSix from '../../components/features/FeatureSix';

const HomeStartup = () => {
  return (
    <Layout>
      <PageMeta title='Allodat: AI Consulting'/>
      <Navbar navDark posAbsolute />
      <HeroMain />
      <Customer gray />
      <WorkProcess />
      <IntegrationTwo />
      <FeatureFour />
      <CtaOne />
      <LatestBlog/>
      <FeatureSix/>
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default HomeStartup;
