import React, { useState } from "react";

const ContactFormTwo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataObj = { name, email, phone_number: phoneNumber, message };

    try {
      const response = await fetch("https://airtable-form-handler.allodatpvtltd.workers.dev/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formDataObj).toString(),
      });

      if (response.ok) {
        setSubmissionMessage("Success! We will contact you soon.");
        setIsError(false);
        // Reset form fields
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
      } else {
        setSubmissionMessage("Unable to send response. Please try again.");
        setIsError(true);
      }
    } catch (error) {
      setSubmissionMessage("Network error. Please check your connection.");
      setIsError(true);
    }
  };

  return (
    <>
      <section
        className="contact-us-form pt-60 pb-120"
        style={{
          background: "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-heading">
                <h2>Talk to our Team</h2>
                <p>
                  Collaboratively promote client-focused convergence vis-a-vis
                  customer directed alignments via standardized infrastructures.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="register-form">
              <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="firstName" className="mb-1">
                      Name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        required
                        placeholder="Your Name"
                        aria-label="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="email" className="mb-1">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder="Email"
                        aria-label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="phoneNumber" className="mb-1">
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="tel"
                        name="phone_number"
                        className="form-control"
                        id="phoneNumber"
                        required
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        pattern="\d{10,14}"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourMessage" className="mb-1">
                      Message <span className="text-danger">*</span>
                    </label>
                    <div className="input-group mb-3">
                      <textarea
                        className="form-control"
                        id="yourMessage"
                        name="message"
                        required
                        placeholder="How can we help you?"
                        style={{ height: "120px" }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                  Get in Touch
                </button>
                {submissionMessage && (
                  <p style={{ color: isError ? "red" : "green", marginTop: "20px" }}>
                    {submissionMessage}
                  </p>
                )}
              </form>
            </div>
            <div className="col-lg-5 col-md-10">
              <div className="contact-us-img">
                <img src="assets/img/office.png" alt="contact us" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
