import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../common/SectionTitle";

const LatestBlog = () => {
  return (
    <>
      <section className="related-blog-list ptb-60 bg-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-4 col-md-12">
              <SectionTitle
                subtitle="Blog"
                title="Check our Latest News and Update"
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="text-start text-lg-end mb-4 mb-lg-0 mb-xl-0">
                <Link to="/blogs" className="btn btn-primary">
                  View All Article
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom mb-4 mb-lg-0">
                <Link to="/wine-wizard" className="article-img">
                  <img
                    src="assets/img/wine/3.png"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="#"
                      className="d-inline-block text-dark badge bg-warning-soft"
                    >
                      AI App
                    </Link>
                  </div>
                  <Link to="/wine-wizard">
                    <h2 className="h5 article-title limit-2-line-text">
                      Unveiling WineWizard: Revolutionizing the Wine Shopping
                      Experience with AI
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                    Tackling the challenge of navigating through an extensive
                    range of over 30,000 wines, our team at Allodat embarked on
                    creating WineWizard, a custom LLM application designed to
                    revolutionize the wine e-commerce experience. WineWizard was
                    born from the need to streamline the wine selection process,
                    providing customers with personalized recommendations,
                    detailed wine information, and efficient responses to
                    queries.
                  </p>

                  <Link>
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/team/team-2.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Shreya Misra</h6>
                        <span className="small fw-medium text-muted">
                          January 24, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-article rounded-custom mb-4 mb-lg-0 mb-md-0">
                <Link to="/comply-net" className="article-img">
                  <img
                    src="assets/img/comply-net/1.png"
                    alt="article"
                    className="img-fluid"
                  />
                </Link>
                <div className="article-content p-4">
                  <div className="article-category mb-4 d-block">
                    <Link
                      to="/comply-net"
                      className="d-inline-block text-dark badge bg-danger-soft"
                    >
                      AI Chatbot
                    </Link>
                  </div>
                  <Link to="/comply-net">
                    <h2 className="h5 article-title limit-2-line-text">
                    ComplyNet Unveiled: Transforming Compliance Navigation with AI
                    </h2>
                  </Link>
                  <p className="limit-2-line-text">
                  ComplyNet, a trailblazer in compliance and security automation, faced a formidable challenge: enabling clients to navigate a vast sea of compliance benchmarks, frameworks, and audit-related data efficiently. This complexity often overwhelmed clients, hindering their ability to access tailored information swiftly.
                  </p>

                  <Link>
                    <div className="d-flex align-items-center pt-4">
                      <div className="avatar">
                        <img
                          src="assets/img/team/team-2.jpg"
                          alt="avatar"
                          width="40"
                          className="img-fluid rounded-circle me-3"
                        />
                      </div>
                      <div className="avatar-info">
                        <h6 className="mb-0 avatar-name">Shreya Misra</h6>
                          <span className="small fw-medium text-muted">
                            March 18, 2024
                          </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlog;
