import React from "react";
import { Link } from "react-router-dom";

const OpenJobs = () => {
  return (
    <>
      <section id="open-positions" class="open-jobs ptb-120">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <di v class="col-lg-4 col-md-12">
              <div class="section-heading">
                <h4 class="h5 text-primary">Our Jobs</h4>
                <h2>Current Available Positions at allodat</h2>
              </div>
            </di>
            <div class="col-lg-7 col-md-12">
              <p>
                Explore Opportunities at Allodat. Dive into a world where your
                expertise in AI can flourish. With us, you'll work on
                groundbreaking projects, pushing the boundaries of technology
                and innovation. Join our team and be part of the future, today.{" "}
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <Link
                to="/contact-us"
                className="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="job-time h-6 mb-2">
                    <i className="far fa-briefcase me-2"></i>
                    <strong>Remote - Full Time</strong>
                  </span>
                  <span className="badge px-3 py-2 bg-custom-light rounded-pill small">
                    Developer
                  </span>
                </div>
                <h3 className="h5">Jr. AI Developer</h3>
                <ul className="job-info-list list-inline list-unstyled text-muted">
                  <li className="list-inline-item">
                    <span className="far fa-house-building me-1"></span> Delhi, India
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-map-marker-alt me-1"></span> Remote
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-wallet me-1"></span> Competitive
                  </li>
                </ul>
                <div className="btn btn-outline-light btn-sm d-inline-block mt-4">
                  Apply Now
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-12">
              <Link
                to="/contact-us"
                className="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="job-time h-6 mb-2">
                    <i className="far fa-briefcase me-2"></i>
                    <strong>Remote - Full Time</strong>
                  </span>
                  <span className="badge px-3 py-2 bg-custom-light rounded-pill small">
                    Designer
                  </span>
                </div>
                <h3 className="h5">AI Interaction Designer</h3>
                <ul className="job-info-list list-inline list-unstyled text-muted">
                  <li className="list-inline-item">
                    <span className="far fa-house-building me-1"></span> Delhi, India
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-map-marker-alt me-1"></span> Remote
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-wallet me-1"></span> Competitive
                  </li>
                </ul>
                <div className="btn btn-outline-light btn-sm d-inline-block mt-4">
                  Apply Now
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-12">
              <Link
                to="/contact-us"
                className="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="job-time h-6 mb-2">
                    <i className="far fa-briefcase me-2"></i>
                    <strong>Full Time</strong>
                  </span>
                  <span className="badge px-3 py-2 bg-custom-light rounded-pill small">
                    Manager
                  </span>
                </div>
                <h3 className="h5">AI Project Manager</h3>
                <ul className="job-info-list list-inline list-unstyled text-muted">
                  <li className="list-inline-item">
                    <span className="far fa-house-building me-1"></span> Delhi, India
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-map-marker-alt me-1"></span>{" "}
                    Remote/On-site
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-wallet me-1"></span> Competitive
                  </li>
                </ul>
                <div className="btn btn-outline-light btn-sm d-inline-block mt-4">
                  Apply Now
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-md-12">
              <Link
                to="/contact-us"
                className="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="job-time h-6 mb-2">
                    <i className="far fa-briefcase me-2"></i>
                    <strong>Remote</strong>
                  </span>
                  <span className="badge px-3 py-2 bg-custom-light rounded-pill small">
                    Developer
                  </span>
                </div>
                <h3 className="h5">Senior AI Developer</h3>
                <ul className="job-info-list list-inline list-unstyled text-muted">
                  <li className="list-inline-item">
                    <span className="far fa-house-building me-1"></span> Delhi, India
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-map-marker-alt me-1"></span>{" "}
                    Flexible
                  </li>
                  <li className="list-inline-item">
                    <span className="far fa-wallet me-1"></span> Competitive
                  </li>
                </ul>
                <div className="btn btn-outline-light btn-sm d-inline-block mt-4">
                  Apply Now
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OpenJobs;
