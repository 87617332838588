import React from "react";
import SectionTitle from "../common/SectionTitle";

const FeatureFour = () => {
  return (
    <>
      <section className="feature-section ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                subtitle="Quality Partners"
                title="Alliances & Partners"
                description="We strategically collaborate with a diverse ecosystem of partners and platforms, ensuring unparalleled flexibility and swift outcomes while maintaining quality and excellence."
                centerAlign
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/alliances/1.svg" alt="shape" />
                </div>
                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/alliances/2.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/alliances/3.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/alliances/4.svg" alt="shape" />
                </div>

                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFour;
