import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const IntegrationTwo = () => {
  return (
    <>
      <section className='integration-section bg-light ptb-60'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle='LLM Models'
                title='AI Models We Have Expertise In'
                description='  Explore the diversity of AI models our team excels in, designed to propel your business into the future.'
                leftAlign
              />
            </div>
            <div className='col-lg-4 col-md-12'>
              <div className='text-lg-end mb-5 mb-lg-0' data-aos='fade-left'>
                <Link to='/contact-us' className='btn btn-primary'>
                  Get-In-Touch
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='integration-wrapper position-relative w-100'>
                <ul className='integration-list list-unstyled mb-0'>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/1.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/2.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/3.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/4.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/5.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/6.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/7.png'
                        alt='integration'
                        className='img-fluid'
                      />  
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/8.svg'
                        alt='integration'
                        className='img-fluid'
                      />  
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/9.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/10.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/11.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                  <li data-aos='fade-up' data-aos-delay='50'>
                    <img
                        src='assets/img/llm_models/12.svg'
                        alt='integration'
                        className='img-fluid'
                      />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationTwo;
