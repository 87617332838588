import React from "react";

const FeatureImgContentThree = () => {
  return (
    <>
      <section className="feature-section pt-60 pb-60">
        <div className="container">
          <div className="row align-items-lg-center justify-content-between">
            <div className="col-lg-5 order-lg-1 mb-7 mb-lg-0">
              <div className="mb-4">
                <h2>Maximize Value with Data Engineering</h2>
                <p>
                  At Allodat, our data engineering services transform your raw data into a strategic asset. Utilizing advanced analytics and machine learning, we extract actionable insights, enabling informed decision-making and driving your business towards new heights of efficiency and growth.
                </p>
              </div>
              <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5">
                <li>
                  <span>
                    <i className="fal fa-analytics fa-2x text-primary mb-4"></i>
                  </span>
                  <h3 className="h5">Data Analytics</h3>
                  <p>
                    Dive deep into data with our analytics, identifying trends and opportunities.
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fal fa-robot fa-2x text-primary mb-4"></i>
                  </span>
                  <h3 className="h5">Machine Learning</h3>
                  <p>Power your solutions with ML models trained on high-quality, annotated data.</p>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 order-lg-2">
              <div className="pr-lg-4 position-relative">
                <div className="bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto">
                  <img
                    src="assets/img/services_gen_ai/2.svg"
                    alt="Data Engineering Insight"
                    className="img-fluid rounded-custom shadow-sm"
                  />
                  <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentThree;