import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "../pages/services/Services";
import NotFoundScreen from "../components/others/NotFoundScreen";
import ScrollToTop from "../components/common/ScrollToTop";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Blogs from "../pages/blog/Blogs";
import SingleBlog1 from "../pages/blog/SingleBlog1";
import SingleBlog2 from "../pages/blog/SingleBlog2";
import Career from "../pages/career/Career";
import CareerSingle from "../pages/career/CareerSingle";
import HelpCenter from "../pages/help-center/HelpCenter";
import HomeMain from "../themes/index_main/HomeMain";

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeMain />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/wine-wizard" element={<SingleBlog1 />} />
        <Route exact path="/comply-net" element={<SingleBlog2 />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/career-single" element={<CareerSingle />} />
        <Route exact path="/help-center" element={<HelpCenter />} />
        <Route exact path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
