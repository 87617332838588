import React from "react";
import SectionTitle from "../common/SectionTitle";

const FeatureSix = () => {
  return (
    <>
      <section className="feature-section ptb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <SectionTitle
                subtitle="Dev"
                title="Our AI Development Technology Stack"
                description="Explore our cutting-edge technology stack, powering innovative AI solutions for tomorrow's challenges."
                centerAlign
              />
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/dl/1.svg" alt="shape" />
                </div>
                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/dl/2.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/dl/3.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/dl/4.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/dl/5.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/backend/1.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/backend/2.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/frontend/1.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/frontend/2.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img src="assets/img/tech_stack/frontend/3.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/python_frameworks/1.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/python_frameworks/2.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/python_frameworks/3.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/libraries/1.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/libraries/2.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/cloud/1.svg"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/cloud/2.png"
                    alt="shape"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 mb-4">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-card rounded-custom text-center custom-shadow p-4 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                  <img
                    src="assets/img/tech_stack/cloud/3.svg"
                    alt="shape"
                  />
                </div>
                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                  <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureSix;
