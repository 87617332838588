import React from 'react';

const ReviewTwo = ({ reqPage }) => {
  return (
    <>
      <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
        <div className='testimonial-tab-slider-wrap mt-5'>
          {reqPage ? (
            <h1 className='fw-bold text-white display-5'>
              Embark on Your AI Journey with Us
            </h1>
          ) : (
            <h2 className='text-white'>Embark on Your AI Journey with Us</h2>
          )}
          <hr />
          <hr />
          <p>
            Partner with Allodat to leverage cutting-edge AI solutions tailored for transformative business growth. Your satisfaction is our utmost priority.
          </p>
          <hr />          
          <div
            className='tab-content testimonial-tab-content text-white-80'
            id='pills-tabContent'
          >
            <div
              className='tab-pane fade show active'
              id='testimonial-tab-1'
              role='tabpanel'
            >
              <blockquote className='blockquote'>
                <em>
                  "Allodat's expertise in AI has propelled our operations forward, driving unparalleled efficiency and innovation in our projects."
                </em>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewTwo;
