import React from 'react';
import BlogGrid from '../../components/blog/BlogGrid';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Blogs = () => {
  return (
    <Layout>
      <PageMeta title="Allodat- AI Consulting" />
      <Navbar navDark />
      <PageHeader
        title="Explore AI Insights and Innovations"
        desc="Dive into the latest trends, research, and breakthroughs in the AI world with our expert analyses and articles. Discover how artificial intelligence is reshaping industries and creating new possibilities."
        blogtags
      />
      <BlogGrid />
      <FooterOne
        style={{
          background:
            "url('assets/img/ai-bg-pattern.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Blogs;

