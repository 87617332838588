import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import SectionTitle from '../common/SectionTitle';

const CtaOne = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className='cta-subscribe pb-120'>
        <div className='container'>
          <div className='bg-gradient ptb-120 position-relative overflow-hidden rounded-custom'>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-8'>
                <div className='subscribe-info-wrap text-center position-relative z-2'>
                  <SectionTitle
                    subtitle="Unlock AI's Potential"
                    title='Begin Your AI Journey'
                    description='Dive into the world of AI with our expertise and transform your business operations for unparalleled growth and efficiency.'
                    dark
                  />
                  <ul className='nav justify-content-center subscribe-feature-list mt-4'>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-primary me-2'></i>
                        Comprehensive AI Consultation
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-primary me-2'></i>
                        24/7 Expert Support
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span>
                        <i className='far fa-check-circle text-primary me-2'></i>
                        Flexible and Scalable Solutions
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5'></div>
            <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
          </div>
          <ModalVideo
            channel='youtube'
            isOpen={isOpen}
            videoId='hAP2QF--2Dg'
            onClose={() => setOpen(false)}
          />
        </div>
      </section>
    </>
  );
};

export default CtaOne;

