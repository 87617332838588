import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgEight = ({ paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-60'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title='Generative AI'
                  description="Unlock the full potential of generative AI with Allodat's end-to-end services. Our expert team specializes in custom solutions like ChatGPT, Midjourney, and DALL-E, tailored to your business objectives. Embrace creativity, operational efficiency, superior customer experiences, and innovation, all while upholding ethical AI standards."
                />
              </div>
              <ul
                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-robot fa-2x text-primary'></i>
                  </span>
                  <h3 className='h5'>AI-Powered Solutions</h3>
                  <p>
                    Deliver personalized experiences and drive innovation with our AI-driven technologies.
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-lightbulb-on fa-2x text-primary'></i>
                  </span>
                  <h3 className='h5'>Innovative Strategy</h3>
                  <p>
                    Strategize with AI to uncover new opportunities and optimize your business processes.
                  </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/services_gen_ai/1.svg'
                    alt='Generative AI Insight'
                    className='img-fluid'
                  />
                  <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgEight;