import React from 'react';
import ProfileCard from './ProfileCard';

const BlogDetails1 = () => {
  return (
    <>
      <section className='blog-details ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>
                <p>
                  Tackling the challenge of navigating through an extensive range of over 30,000 wines, our team at Allodat embarked on creating WineWizard, a custom LLM application designed to revolutionize the wine e-commerce experience. WineWizard was born from the need to streamline the wine selection process, providing customers with personalized recommendations, detailed wine information, and efficient responses to queries.
                </p>
                <p>
                  Collaborating closely with a leading Switzerland-based wine e-commerce company, we leveraged our AI and machine learning expertise to develop a solution that simplifies wine shopping. WineWizard uses sophisticated algorithms to analyze user preferences and offer tailored wine suggestions, ensuring a seamless and intuitive shopping experience for every wine enthusiast.
                </p>
                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>Revolutionizing Wine Shopping with WineWizard</h3>
                  <ul className='content-list list-unstyled'>
                    <li>
                      Personalized wine recommendations that adapt to user tastes and preferences.
                    </li>
                    <li>
                      An efficient search functionality that allows users to find specific wines, regions, or types effortlessly.
                    </li>
                    <li>
                      Multilingual support, making WineWizard accessible to a wide array of customers, including those who speak Dutch and Italian.
                    </li>
                    <li>
                      Real-time availability checks to ensure users can purchase their preferred wines without disappointment.
                    </li>
                    <li>
                      Comprehensive wine profiles offering insights into flavor notes, winery backgrounds, and food pairing suggestions.
                    </li>
                    <li>
                      A user-friendly interface designed to facilitate easy navigation and enhance the overall wine shopping experience.
                    </li>
                  </ul>
                  <blockquote className='bg-white custom-shadow p-5 mt-5 rounded-custom border-4 border-primary border-top'>
                    <p className='text-muted'>
                      <i className='fas fa-quote-left me-2 text-primary'></i>
                      WineWizard not only simplifies the process of selecting the perfect wine but also enriches the customer's journey with detailed insights and personalized experiences, transforming the way wine enthusiasts explore and purchase wine online.
                      <i className='fas fa-quote-right ms-2 text-primary'></i>
                    </p>
                  </blockquote>
                </div>
                <img
                  src='assets/img/wine/2.png'
                  className='img-fluid mt-4 rounded-custom'
                  alt='WineWizard app interface'
                />
                <div className='job-details-info mt-5'>
                  <h3 className='h5'>
                    Enhancing the Customer Experience with WineWizard
                  </h3>
                  <p>
                    WineWizard stands at the forefront of digital innovation in the wine industry, offering a comprehensive suite of features that cater to the diverse needs of wine lovers. By integrating WineWizard into their shopping routine, users gain access to a world of wines tailored specifically to their preferences, complete with detailed descriptions and real-time stock availability.
                  </p>
                  <ul className='content-list list-unstyled'>
                    <li>
                      Discover personalized wine recommendations with ease and sophistication.
                    </li>
                    <li>
                      Seamlessly navigate through an extensive selection to find wines that perfectly match your taste.
                    </li>
                    <li>
                      Enjoy a multilingual platform, ensuring a welcoming experience for all users, regardless of their preferred language.
                    </li>
                  </ul>
                </div>

                <div className='blog-details-info mt-5'>
                  <h3 className='h5'>The Impact of WineWizard</h3>
                  <ul className='content-list list-unstyled'>
                    <li>
                      Users can effortlessly receive wine suggestions that align with their unique tastes and preferences.
                    </li>
                    <li>
                      Gain immediate access to a wealth of information about wines, including types, prices, and regions.
                    </li>
                    <li>
                      Explore wines through a lens of detailed information, from winery stories to expert food pairings.
                    </li>
                  </ul>
                </div>

                <img
                  src='assets/img/wine/1.png'
                  className='img-fluid mt-5 rounded-custom'
                  alt='WineWizard selection process'
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <ProfileCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails1;
